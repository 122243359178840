import { default as _91id_9354JckBaSj7Meta } from "/home/forge/merchants.smake.com/releases/v1.27.1/pages/[slug]/catalog/logos/[id].vue?macro=true";
import { default as indexoEXlyzIWvHMeta } from "/home/forge/merchants.smake.com/releases/v1.27.1/pages/[slug]/catalog/logos/index.vue?macro=true";
import { default as _91id_93u3tyomnAf9Meta } from "/home/forge/merchants.smake.com/releases/v1.27.1/pages/[slug]/customers/[customer]/logos/[id].vue?macro=true";
import { default as indexgaoHt7j4HiMeta } from "/home/forge/merchants.smake.com/releases/v1.27.1/pages/[slug]/customers/[customer]/logos/index.vue?macro=true";
import { default as colorstWP58QMAqFMeta } from "/home/forge/merchants.smake.com/releases/v1.27.1/pages/[slug]/customers/[id]/colors.vue?macro=true";
import { default as favorite_45variantsBJltSaLSCpMeta } from "/home/forge/merchants.smake.com/releases/v1.27.1/pages/[slug]/customers/[id]/favorite-variants.vue?macro=true";
import { default as indextLgeMV26UsMeta } from "/home/forge/merchants.smake.com/releases/v1.27.1/pages/[slug]/customers/[id]/index.vue?macro=true";
import { default as indexoGgQVrVaUnMeta } from "/home/forge/merchants.smake.com/releases/v1.27.1/pages/[slug]/customers/index.vue?macro=true";
import { default as dashboardiiOBl1ofk3Meta } from "/home/forge/merchants.smake.com/releases/v1.27.1/pages/[slug]/dashboard.vue?macro=true";
import { default as entranceZRpqvAUQxRMeta } from "/home/forge/merchants.smake.com/releases/v1.27.1/pages/[slug]/entrance.vue?macro=true";
import { default as indexFtPIW220lHMeta } from "/home/forge/merchants.smake.com/releases/v1.27.1/pages/[slug]/index.vue?macro=true";
import { default as login0gNns2WR9BMeta } from "/home/forge/merchants.smake.com/releases/v1.27.1/pages/[slug]/login.vue?macro=true";
import { default as indexHFZfR5vNC8Meta } from "/home/forge/merchants.smake.com/releases/v1.27.1/pages/index.vue?macro=true";
export default [
  {
    name: "slug-catalog-logos-id",
    path: "/:slug()/catalog/logos/:id()",
    meta: _91id_9354JckBaSj7Meta || {},
    component: () => import("/home/forge/merchants.smake.com/releases/v1.27.1/pages/[slug]/catalog/logos/[id].vue").then(m => m.default || m)
  },
  {
    name: indexoEXlyzIWvHMeta?.name ?? "slug-catalog-logos",
    path: "/:slug()/catalog/logos",
    meta: indexoEXlyzIWvHMeta || {},
    component: () => import("/home/forge/merchants.smake.com/releases/v1.27.1/pages/[slug]/catalog/logos/index.vue").then(m => m.default || m)
  },
  {
    name: "slug-customers-customer-logos-id",
    path: "/:slug()/customers/:customer()/logos/:id()",
    meta: _91id_93u3tyomnAf9Meta || {},
    component: () => import("/home/forge/merchants.smake.com/releases/v1.27.1/pages/[slug]/customers/[customer]/logos/[id].vue").then(m => m.default || m)
  },
  {
    name: "slug-customers-customer-logos",
    path: "/:slug()/customers/:customer()/logos",
    meta: indexgaoHt7j4HiMeta || {},
    component: () => import("/home/forge/merchants.smake.com/releases/v1.27.1/pages/[slug]/customers/[customer]/logos/index.vue").then(m => m.default || m)
  },
  {
    name: "slug-customers-id-colors",
    path: "/:slug()/customers/:id()/colors",
    meta: colorstWP58QMAqFMeta || {},
    component: () => import("/home/forge/merchants.smake.com/releases/v1.27.1/pages/[slug]/customers/[id]/colors.vue").then(m => m.default || m)
  },
  {
    name: "slug-customers-id-favorite-variants",
    path: "/:slug()/customers/:id()/favorite-variants",
    meta: favorite_45variantsBJltSaLSCpMeta || {},
    component: () => import("/home/forge/merchants.smake.com/releases/v1.27.1/pages/[slug]/customers/[id]/favorite-variants.vue").then(m => m.default || m)
  },
  {
    name: "slug-customers-id",
    path: "/:slug()/customers/:id()",
    meta: indextLgeMV26UsMeta || {},
    component: () => import("/home/forge/merchants.smake.com/releases/v1.27.1/pages/[slug]/customers/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexoGgQVrVaUnMeta?.name ?? "slug-customers",
    path: "/:slug()/customers",
    meta: indexoGgQVrVaUnMeta || {},
    component: () => import("/home/forge/merchants.smake.com/releases/v1.27.1/pages/[slug]/customers/index.vue").then(m => m.default || m)
  },
  {
    name: dashboardiiOBl1ofk3Meta?.name ?? "slug-dashboard",
    path: "/:slug()/dashboard",
    meta: dashboardiiOBl1ofk3Meta || {},
    component: () => import("/home/forge/merchants.smake.com/releases/v1.27.1/pages/[slug]/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "slug-entrance",
    path: "/:slug()/entrance",
    meta: entranceZRpqvAUQxRMeta || {},
    component: () => import("/home/forge/merchants.smake.com/releases/v1.27.1/pages/[slug]/entrance.vue").then(m => m.default || m)
  },
  {
    name: "slug",
    path: "/:slug()",
    component: () => import("/home/forge/merchants.smake.com/releases/v1.27.1/pages/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: login0gNns2WR9BMeta?.name ?? "slug-login",
    path: "/:slug()/login",
    meta: login0gNns2WR9BMeta || {},
    component: () => import("/home/forge/merchants.smake.com/releases/v1.27.1/pages/[slug]/login.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/forge/merchants.smake.com/releases/v1.27.1/pages/index.vue").then(m => m.default || m)
  }
]